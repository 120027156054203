<template>
  <div data-app>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body pt-3 pb-0">
        <div class="row">
          <div class="col-md-2">
            <v-autocomplete
              v-model="mandante"
              :items="mandantes"
              item-value="mandanteID"
              item-text="mandanteNombre"
              @change="selectMandante"
              label="Mandante"
              dense
            ></v-autocomplete>
          </div>
          <div class="col-md-2">
            <v-autocomplete
              v-model="campaign"
              :items="campaignsFiltered"
              item-value="campaignID"
              item-text="nombre"
              @change="getTasks"
              label="Campaña"
              dense
            ></v-autocomplete>
          </div>
          <div class="col-md-2">
            <v-text-field
              v-model="task"
              @input="getTasks"
              label="Tarea"
              dense
            ></v-text-field>
          </div>
          <div class="col-md-2">
            <v-autocomplete
              v-model="jobber"
              :items="jobbers"
              item-value="jobberID"
              item-text="jobberNombre"
              @change="getTasks"
              label="Jobber"
              dense
            ></v-autocomplete>
          </div>
          <div class="col-md-2">
            <v-menu
              ref="datepicker"
              v-model="datepicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaGestion"
                  label="Fecha Gestión"
                  v-bind="attrs"
                  v-on="on"
                  class="m-0 p-0"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaGestionOriginal"
                @input="datepicker = false"
                no-title
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="col-md-2">
            <v-autocomplete
              v-model="estado"
              :items="estados"
              item-value="estadoID"
              item-text="estadoDescripcion"
              @change="getTasks"
              label="Estado"
              dense
            ></v-autocomplete>
          </div>
        </div>
      </div>
    </div>
    <TaskList
      :tasks="tasksFiltered"
      :tipo="'list'"
      :estados="estados"
      v-bind:getTasks="getTasks"
      @paginationPageEvent="paginationPageEvent"
      @paginationSizeEvent="paginationSizeEvent"
      @dataSourceEvent="dataSourceEvent"
      :pagination="{ page, totalPages }"
    ></TaskList>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import {
  GET_TASKS_MANDANTE,
  GET_ESTADOS_TASK,
  GET_JOBBERS_MANDANTE,
} from "@/core/services/store/task.module";
import { GET_CAMPAIGNS_MANDANTE } from "@/core/services/store/campaign.module";
import { GET_MANDANTES } from "@/core/services/store/mandante.module";
import { mapGetters } from "vuex";
import TaskList from "../components/task/TaskList.vue";
export default {
  name: "task",
  components: {
    //eslint-disable-next-line
    draggable,
    TaskList,
  },
  data() {
    return {
      itemsTasks: [],
      task: null,
      tasks: [],
      tasksFiltered: [],
      campaign: null,
      campaigns: [],
      campaignsFiltered: [],
      estado: null,
      estados: [],
      jobber: null,
      jobbers: [],
      mandante: null,
      mandantes: [],
      fechaGestion: null,
      fechaGestionOriginal: null,
      datepicker: false,
      page: 1,
      totalPages: 1,
      pageSizes: 10,
      sortBy: "tareaID",
      sortDesc: false,
      search: "",
      pageSize: 10,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Tareas", route: "tasks" }]);

    let oVue = this;

    oVue.mandante =
      oVue.currentUserPersonalInfo.mandanteID === "null"
        ? null
        : oVue.currentUserPersonalInfo.mandanteID;

    const params = oVue.$route.params;
    if (params.fechaGestionOriginal) {
      oVue.fechaGestionOriginal = params.fechaGestionOriginal;
    }
    if (params.jobber) {
      oVue.jobber = params.jobber;
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    paginationPageEvent(value) {
      this.page = value;
      this.getTasks();
    },
    paginationSizeEvent(value) {
      this.pageSize = value;
      this.getTasks();
    },
    dataSourceEvent(value) {
      if (!this.mandante) return;

      this.sortBy = value.sortBy;
      this.sortDesc = value.sortDesc;
      this.search = value.search;

      this.getTasks();
    },
    initialize() {
      this.getEstados();
      this.getMandantes();
      this.getJobbers();
    },
    getTasks() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.tasks = oVue.itemsTasks = oVue.tasksFiltered = [];

      const sortBy = oVue.sortBy || null;
      const sortDesc = oVue.sortDesc || false;

      const data = {
        queryParams: {
          page: oVue.page,
          items_per_page: oVue.pageSize,
          sort: sortBy,
          order: sortDesc ? "desc" : "asc",
          search: oVue.search,
          report: 0,
        },
      };

      if (oVue.mandante) data.mandante = oVue.mandante;
      if (oVue.campaign) data.campaign = oVue.campaign;
      if (oVue.task) data.job = oVue.task;
      if (oVue.jobber) data.jobber = oVue.jobber;
      if (oVue.estado) data.state = oVue.estado;
      if (oVue.fechaGestion) data.managmentDate = oVue.fechaGestion;

      if (
        !oVue.mandante &&
        !oVue.campaign &&
        !oVue.task &&
        !oVue.jobber &&
        !oVue.estado &&
        !oVue.fechaGestion
      ) {
        KTUtil.removeLoading();
        return;
      }

      oVue.$store
        .dispatch(GET_TASKS_MANDANTE, data)
        .then(({ data: itemsTasks, payload }) => {
          oVue.totalPages = payload?.pagination?.links?.length || 1;
          oVue.itemsTasks = oVue.tasksFiltered = itemsTasks;
          let tasks = JSON.parse(JSON.stringify(itemsTasks));
          let array = [];
          array.unshift("");
          tasks.forEach((task) => {
            array.push(task.tareaID);
          });
          oVue.tasks = array;

          KTUtil.removeLoading();
        });
    },
    getCampaigns() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store
        .dispatch(GET_CAMPAIGNS_MANDANTE, oVue.mandante)
        .then((campaigns) => {
          campaigns.unshift({ campaignID: "", nombre: "" });
          oVue.campaigns = oVue.campaignsFiltered = campaigns.map(
            ({ campaignID, nombre }) => ({ campaignID, nombre })
          );
          KTUtil.removeLoading();
        });
    },
    getEstados() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.estados = [];
      oVue.$store.dispatch(GET_ESTADOS_TASK).then((estados) => {
        estados.unshift({ estadoID: "", estadoDescripcion: "" });
        oVue.estados = estados;
        KTUtil.removeLoading();
      });
    },
    getJobbers() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store
        .dispatch(GET_JOBBERS_MANDANTE, oVue.mandante)
        .then((jobbers) => {
          jobbers.unshift({ jobberID: "", jobberNombre: "" });
          oVue.jobbers = oVue.jobbersFiltered = jobbers;
          const jobberIndex = jobbers.findIndex((j) =>
            j.jobberNombre.includes(oVue.jobber)
          );
          oVue.jobber = oVue.jobbers[jobberIndex];
          KTUtil.removeLoading();
        });
    },
    getMandantes() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_MANDANTES).then((mandantes) => {
        mandantes.unshift({ mandanteID: "", mandanteNombre: "" });
        oVue.mandantes = mandantes;
        KTUtil.removeLoading();
      });
    },
    taskFilter() {
      let oVue = this;
      if (!oVue.task) {
        return oVue.tasksFiltered;
      }
      oVue.tasksFiltered = oVue.tasksFiltered.filter((t) => {
        if (t.tareaID) {
          let tareaID = t.tareaID.toString();
          return tareaID.includes(oVue.task);
        } else {
          return false;
        }
      });
    },
    campaignFilter() {
      let oVue = this;
      if (!oVue.campaign) {
        return oVue.tasksFiltered;
      }
      oVue.tasksFiltered = oVue.tasksFiltered.filter((t) => {
        return t.campanaNombre == oVue.campaign.nombre;
      });
    },
    jobberFilter() {
      let oVue = this;
      if (!oVue.jobber) {
        return oVue.tasksFiltered;
      }
      oVue.tasksFiltered = oVue.tasksFiltered.filter((t) => {
        if (t.jobberNombre) {
          return t.jobberNombre.includes(oVue.jobber.jobberNombre);
        } else {
          return false;
        }
      });
    },
    estadoFilter() {
      let oVue = this;
      if (!oVue.estado) {
        return oVue.tasksFiltered;
      }
      oVue.tasksFiltered = oVue.tasksFiltered.filter((t) => {
        return t.estado == oVue.estado.estadoDescripcion;
      });
    },
    mandanteFilter() {
      let oVue = this;
      if (!oVue.mandante) {
        return oVue.tasksFiltered;
      }
      oVue.tasksFiltered = oVue.tasksFiltered.filter((t) => {
        return t.mandanteNombre == oVue.mandante.mandanteNombre;
      });
    },
    fechaGestionFilter() {
      let oVue = this;
      if (!oVue.fechaGestion) {
        return oVue.tasksFiltered;
      }
      oVue.tasksFiltered = oVue.tasksFiltered.filter((t) => {
        if (t.fechaGestion) {
          return t.fechaGestion.includes(oVue.fechaGestion);
        } else {
          return false;
        }
      });
    },
    async selectMandante() {
      this.campaign = null;
      this.jobber = null;
      await this.getTasks();
      await this.getCampaigns();
      await this.getJobbers();
    },
    formatDate(date) {
      if (!date) return null;

      return date;
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    //eslint-disable-next-line no-unused-vars
    fechaGestionOriginal(val) {
      this.fechaGestion = this.formatDate(this.fechaGestionOriginal);
    },
    //eslint-disable-next-line no-unused-vars
    fechaGestion(val) {
      this.getTasks();
    },
  },
};
</script>
